import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box } from "theme-ui";
import { getVersion } from "@tauri-apps/api/app";
import { isTauri } from "../../lib/tauri";

const Version = styled.code`
	cursor: help;
`;

const Footer: React.FunctionComponent = () => {
	const [version, setVersion] = useState("");

	useEffect(() => {
		if (!isTauri()) {
			return;
		}

		getVersion().then(setVersion);
	}, []);

	return (
		<Box p={2} sx={{ fontSize: 1 }}>
			<a href={`//${ECOGARDEN_URL}/`}>Ecogarden</a>{" "}
			<Version
				data-version={ECOGARDEN_VERSION}
				data-buildref={BUILDREF}
				data-app-version={version}
			>
				<>
					<span title="Ecogarden Version">
						{ECOGARDEN_VERSION} - {BUILDREF}
					</span>
					<span title="App Version">{version}</span>
				</>
			</Version>
		</Box>
	);
};

export default Footer;
