import Logo from "./Logo";
import styled from "styled-components";

const LogoIcon = styled(Logo)`
  pointer-events: all;
  transition: all 96ms ease-in;
  cursor: pointer;
  border: 2px solid var(--theme-ui-colors-background);
  box-shadow: 1px 1px 2px var(--accent-color),
    0 0 6px 4px var(--accent-color-20);

  :hover,
  :focus {
    box-shadow: 1px 1px 2px var(--accent-color), 0 0 6px 4px gold;
  }

  :active {
    box-shadow: 2pt 2pt 0 transparent;
    transform: translate(1pt, 1pt);
  }

  path,
  g {
    pointer-events: none;
  }
`;

export default LogoIcon;
