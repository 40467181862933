import styled from "styled-components";

const NavList = styled.ul`
  padding: 0;
  list-style: none;
  pointer-events: all;
  max-width: 320px;
`;

export default NavList;
