import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Box, Grid } from "theme-ui";
import { close, open } from "../../actions/panels";
import type { RootState } from "../../lib/configureStore";
import Logo from "./LogoIcon";

/**
 * Title to the settings header
 */
/* const Title = styled.h1` */
/*   margin: 0; */
/*   font-weight: 600; */
/*   color: var(--fg-color); */
/*   text-shadow: 0 0 0.2em var(--bg-color), 0 0 0.3em var(--accent-bg-color); */
/* `; */

/**
 * Settings title name
 */
const Name: React.FunctionComponent<
	React.PropsWithChildren<{ title: string }>
> = ({ children, title, ...props }) => {
	const isOpen = useSelector((state: RootState) => state.panels.settings);
	return (
		<Box
			as="h1"
			sx={{
				textShadow:
					"0 0 0.2em var(--bg-color), 0 0 0.3em var(--accent-bg-color)",
				fontSize: [2, 3],
			}}
			title={title}
			{...props}
		>
			{isOpen ? <a href="/">{children}</a> : <></>}
		</Box>
	);
};

/**
 * Header to the settings component
 */
// eslint-disable-next-line max-lines-per-function
const Header: React.FunctionComponent = () => {
	const isOpen = useSelector((state: RootState) => state.panels.settings);
	const dispatch = useDispatch();

	const closePanel = (): void => {
		dispatch(close("settings"));
	};

	const openPanel = (): void => {
		dispatch(open("settings"));
	};

	return (
		<Grid
			px={1}
			columns={2}
			sx={{
				gridTemplateColumns: "42px auto",
				alignItems: "center",
				userSelect: "none",
			}}
		>
			<Logo
				aria-label="Logo"
				width="42"
				height="42"
				stroke="darkgreen"
				onClick={isOpen ? closePanel : openPanel}
				role="img"
				aria-hidden="true"
				style={{ pointerEvents: "all" }}
			/>
			<Name title="Ecogarden">
				Ecogarden
				<Badge variant="accent" mx={2} mt={-2}>
					Beta
				</Badge>
			</Name>
		</Grid>
	);
};

export default Header;
