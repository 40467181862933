import Mousetrap from "mousetrap";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "theme-ui";
import { close } from "../../actions/panels";
import type { RootState } from "../../lib/configureStore";
import Header from "./Header";
import SettingsPanel from "./SettingsPanel";

const SettingsContainer: React.FunctionComponent = () => {
  const isOpen = useSelector((state: RootState) => state.panels.settings);
  const dispatch = useDispatch();
  const mousetrap = useMemo(() => new Mousetrap(), []);

  useEffect(() => {
    mousetrap.bind("esc", () => {
      dispatch(close("settings"));
    });

    return function cleanup(): void {
      mousetrap.unbind("esc");
    };
  });

  return (
    <Grid sx={{ zIndex: 3, pointerEvents: "none", alignItems: "flex-start" }}>
      {!isOpen && (
        <Box p={2} sx={{ position: "absolute" }}>
          <Header />
        </Box>
      )}
      {isOpen && <SettingsPanel />}
    </Grid>
  );
};

export default SettingsContainer;
