import React from "react";
import { Box, Label } from "theme-ui";

import Name from "./SiteName";

const SiteOptions: React.FunctionComponent = () => {
  return (
    <Box p={2}>
      <Label htmlFor="site-name">Your Garden Name</Label>
      <Name id="site-name" />
    </Box>
  );
};

export default SiteOptions;
