/* eslint-disable max-lines-per-function */
import React, { useState } from "react";
import type { Dispatch } from "redux";
import { connect } from "react-redux";

import { setSiteName } from "../../actions/settings";

import EditableText from "../EditableText";

import type { RootState } from "../../lib/configureStore";

const theName = (name: string): string =>
	name != "" && name != null ? name : "Ecogarden";

const actualName = (settings: RootState["settings"]): string =>
	theName(settings.name);

interface Props extends Pick<RootState, "settings"> {
	readonly onChange?: React.EventHandler<React.FormEvent>;
	readonly dispatch: Dispatch;
	readonly id?: string;
}

/**
 * Handle Ecogarden Site Name changes
 */
const SiteName: React.FunctionComponent<Props> = ({
	settings,
	onChange,
	dispatch,
	...props
}) => {
	const [name, setName] = useState(actualName(settings));

	const handleChange: React.EventHandler<React.FormEvent> = (e) => {
		const target = e.target as HTMLInputElement;

		setName(target.value);
		onChange && onChange(e);
	};

	const handleSubmit: (
		e: Pick<React.FormEvent, "preventDefault">
	) => void = () => dispatch(setSiteName(name));
	const handleCancel: React.EventHandler<React.MouseEvent> = () =>
		setName(actualName(settings));

	return (
		<EditableText
			value={name}
			placeholder="Ecogarden"
			onChange={handleChange}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			formSx={{
				gridTemplateColumns: "1fr",
				gridAutoFlow: "row",
				justifyItems: "flex-end",
			}}
			{...props}
		/>
	);
};

export default connect((state: RootState) => ({ settings: state.settings }))(
	SiteName
);
