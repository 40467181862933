import styled from "@emotion/styled";
import type { fabric } from "fabric";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { close } from "../../actions/panels";
import { getCanvas } from "../../design/canvas";
import type { RootState } from "../../lib/configureStore";
import { isTauri } from "../../lib/tauri";
import TextLinkButton from "../buttons/TextLinkButton";
import LoadDesignModal from "../files/LoadDesignModal";
import SaveModal from "../files/SaveModal";
import SaveIcon from "../icons/save.svg";
import UploadIcon from "../icons/upload.svg";
import NavItem from "./NavItem";
import NavList from "./NavList";

const FullTextLinkButton = styled(TextLinkButton)`
	width: 100%;
`;

const FileOptions: React.FunctionComponent<{
	readonly canvas?: fabric.Canvas;
	// eslint-disable-next-line max-lines-per-function
}> = ({ canvas }) => {
	const [showLoadDesignModal, setShowLoadDesignModal] = useState(false);
	const [showSaveDesignModal, setShowSaveDesignModal] = useState(false);

	const [canExport, setCanExport] = useState(false);

	const dispatch = useDispatch();

	const closeModal = (): void => {
		setShowSaveDesignModal(false);
		setShowLoadDesignModal(false);

		dispatch(close("settings"));
	};

	useEffect(() => {
		if (!canvas) {
			return;
		}
		setCanExport(canvas._objects.length > 0);
	}, [canvas]);

	return (
		<NavList>
			<NavItem>
				<FullTextLinkButton
					type="button"
					onClick={(e): void => {
						e.preventDefault();
						setShowLoadDesignModal(true);
					}}
				>
					<UploadIcon /> Load
				</FullTextLinkButton>
				<LoadDesignModal isOpen={showLoadDesignModal} onClose={closeModal} />
			</NavItem>
			{canExport && (
				<NavItem>
					<FullTextLinkButton
						type="button"
						onClick={(e): void => {
							e.preventDefault();
							setShowSaveDesignModal(true);
						}}
					>
						<SaveIcon /> {isTauri() ? "Save" : "Export"}
					</FullTextLinkButton>
					<SaveModal isOpen={showSaveDesignModal} onClose={closeModal} />
				</NavItem>
			)}
		</NavList>
	);
};

export default connect((state: RootState) => ({
	canvas: getCanvas(state.canvas),
}))(FileOptions);
