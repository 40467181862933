import React, { useState } from "react";
import styled from "styled-components";
import { useStore, connect, batch } from "react-redux";
import type { Store } from "redux";
import { fabric } from "fabric";

import type { RootState } from "../../lib/configureStore";

import { clearCanvas } from "../../actions/global";

import { getCanvas } from "../../design/canvas";

import ConfirmModal from "../modals/ConfirmModal";
import TextLinkButton from "../buttons/TextLinkButton";

import AlertTriangleIcon from "../icons/alert-triangle.svg";
import { ActionCreators } from "redux-undo";
const FullTextLinkButton = styled(TextLinkButton)`
  width: 100%;
`;

/**
 * Callbacks to fabric.Canvas.fxRemove type
 */
interface FxFabricCanvas extends fabric.Canvas {
  readonly fxRemove: (
    obj: fabric.Object,
    callbacks?: { readonly onComplete: () => void }
  ) => fabric.StaticCanvas;
}

/**
 * Promise wrapper for fabric.Canvas.fxRemove
 */
const fxRemove =
  (canvas: FxFabricCanvas) =>
  (obj: fabric.Object): Promise<unknown> => {
    // eslint-disable-next-line compat/compat
    return new Promise((resolve) => {
      canvas.fxRemove(obj, {
        onComplete: () => resolve(obj),
      });
    });
  };

const handleResetDesign =
  (store: Store) => (canvas?: fabric.Canvas) => (): void => {
    if (!canvas) {
      return;
    }

    Promise.all(canvas.getObjects().map(fxRemove(canvas))).then(() => {
      canvas.clear();
      canvas.setZoom(1);
      canvas.absolutePan(new fabric.Point(0, 0));
      canvas.requestRenderAll();
      batch(() => {
        store.dispatch(clearCanvas());
        store.dispatch(ActionCreators.clearHistory());
      });
    });
  };

/**
 * Close the clear canvas modal
 */
const close =
  (setShowModal: React.Dispatch<React.SetStateAction<boolean>>) => (): void => {
    setShowModal(false);
  };

/**
 * Clear Canvas with modal
 */
// eslint-disable-next-line max-lines-per-function
const ClearCanvas: React.FunctionComponent<{
  readonly canvas?: fabric.Canvas;
  // eslint-disable-next-line max-lines-per-function
}> = ({ canvas }) => {
  const [showModal, setShowModal] = useState(false);
  const store = useStore();

  if (!canvas) {
    return <div>Invalid canvas to clear.</div>;
  }

  return (
    <>
      <FullTextLinkButton
        type="button"
        onClick={(e): void => {
          e.preventDefault();
          setShowModal(true);
        }}
        sx={{ fontWeight: "normal" }}
      >
        <AlertTriangleIcon /> Reset Design
      </FullTextLinkButton>
      <ConfirmModal
        caution
        isOpen={showModal}
        onConfirm={(): void => {
          handleResetDesign(store)(canvas)();
          setShowModal(false);
        }}
        onCancel={close(setShowModal)}
        onRequestClose={close(setShowModal)}
        actionProps={{ variant: "alert" }}
      >
        Reset your design?
      </ConfirmModal>
    </>
  );
};

export default connect((state: RootState) => ({
  canvas: getCanvas(state.canvas),
}))(ClearCanvas);
