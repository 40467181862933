/* eslint-disable max-lines-per-function */
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Grid } from "theme-ui";
import { setFeature } from "../../actions/features";
import { useAppSelector } from "../../lib/configureStore";
import type { Features as Feature } from "../../reducers/featuresReducer";
import MenuHeader from "../MenuHeader";
import InfoModal from "../modals/InfoModal";

const Features = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const features = useAppSelector((state) => {
    return state.features;
  });
  const toggle = (feature: Feature) => () => {
    //
    dispatch(setFeature({ [feature]: features[feature] ? false : true }));
  };

  return (
    <Box>
      <Box>
        <button
          sx={{
            border: "none",
            background: "none",
            opacity: 0.2,
            color: "red",
          }}
          title="Secret Up and Coming Features"
          onClick={() => setIsOpen(isOpen ? false : true)}
        >
          F
        </button>
      </Box>
      <InfoModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <MenuHeader onCloseRequest={() => setIsOpen(false)}>
          Features
        </MenuHeader>
        <Box sx={{ p: 3 }}>
          These features are being developed and may not be fully working. Any
          and all actions can{" "}
          <em sx={{ fontWeight: "bold", color: "red" }}>break your design.</em>{" "}
          Please backup your design being enabling these.
        </Box>
        <Grid sx={{ gridGap: 2, paddingX: 3 }}>
          {(
            [
              "freedraw-tool",
              "accent-color",
              "stroke-color",
              "line-tool",
              "object-lock",
              "object-visible",
              "image-shape",
              "label-overlay",
              "objects-list",
              "boids-distribution",
              "plant-on-line",
            ] as readonly Feature[]
          ).map((feature: Feature) => {
            return (
              <Box
                sx={{
                  cursor: "pointer",
                  backgroundColor: features[feature]
                    ? "accent-bg"
                    : "transparent",
                  paddingY: 2,
                  paddingX: 2,
                }}
                key={feature}
                onClick={toggle(feature)}
              >
                {feature} {features[feature] ? "on" : "off"}
              </Box>
            );
          })}
        </Grid>
      </InfoModal>
    </Box>
  );
};

export default Features;
