import React from "react";
import { Box } from "theme-ui";
import { keyframes } from "@emotion/css";

import NavItem from "./NavItem";
import NavList from "./NavList";

import Header from "./Header";
import Footer from "./Footer";

import FileOptions from "./FileOptions";
import SiteOptions from "./SiteOptions";
import CanvasOptions from "./CanvasOptions";
import Feedback from "../Feedback";
import Features from "./Features";
import Controls from "../Controls";
import HelpContainer from "../help/HelpContainer";

const slideleft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  50% {
    opacity: .1;
  }

  90% {
    opacity: .9;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

// eslint-disable-next-line max-lines-per-function
const SettingsPanel: React.FunctionComponent = () => (
  <Box
    p={2}
    bg="background"
    sx={{
      position: "fixed",
      animation: `${slideleft} 192ms ease-in`,
      height: "100%",
      pointerEvents: "auto",
      borderRight: "1px solid",
      borderColor: "accent",
      boxShadow: "0 0 2pt 1pt var(--theme-ui-colors-accent-bg)",
      overflow: "auto",
    }}
    as="nav"
  >
    <Header />

    <NavList>
      <NavItem>
        <SiteOptions />
      </NavItem>

      <NavItem>
        <FileOptions />
      </NavItem>

      <NavItem>
        <CanvasOptions />
      </NavItem>

      <NavItem>
        <Controls />
      </NavItem>

      <NavItem>
        <HelpContainer />
      </NavItem>

      <NavItem>
        <Feedback />
      </NavItem>

      <NavItem>
        <Footer />
      </NavItem>

      <NavItem>
        <Features />
      </NavItem>
    </NavList>
  </Box>
);

export default SettingsPanel;
