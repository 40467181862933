import React from "react";

import NavList from "./NavList";
import NavItem from "./NavItem";
import ClearCanvas from "./ClearCanvas";

const CanvasOptions: React.FunctionComponent = () => {
  return (
    <NavList>
      <NavItem>
        <ClearCanvas />
      </NavItem>
    </NavList>
  );
};

export default CanvasOptions;
